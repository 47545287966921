import React from 'react'
import { Divider, SideNav, Heading } from '@enterprise-ui/canvas-ui-react'
import { Link, useLocation } from 'react-router-dom'
import { useAnalytics } from '@praxis/component-analytics'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import EnterpriseIcon, {
  FolderIcon,
  ChevronRightIcon,
} from '@enterprise-ui/icons'
import { useAuth, useEntitlements } from 'properties-ui-common'
import '../MainWrapper.css'
import logo from '../../../images/mosaic.png'
import { sideNavigationArray } from './SideNavigationItems'

interface SideNavigationProps {
  sideNavOpen: boolean
  setIsSideNavOpen: Function
  sideNavMinimized: boolean
  setSideNavMinimized: Function
  isMenuButton: boolean
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  sideNavOpen,
  setIsSideNavOpen,
  sideNavMinimized,
  setSideNavMinimized,
  isMenuButton,
}) => {
  const auth = useAuth()
  const { pathname } = useLocation()
  const { trackClick, trackCustomEvent } = useAnalytics()
  if (!auth.isAuthenticated()) {
    auth.login({ redirect: window.location.href })
  } else {
    trackCustomEvent('login', 'lanid', auth?.session?.userInfo?.lanId ?? '')
  }
  const { entitlements } = useEntitlements()
  const isPolUser = entitlements?.pol?.read
  const sideNavItems = sideNavigationArray(entitlements)

  const checkIfPolAndNoAccess = (val: any) => {
    if (isPolUser) {
      const allChildrenNoAccess = val?.children?.every(
        (val: { isPolAllowed: any }) => !val?.isPolAllowed,
      )
      return (
        isPolUser && (!val?.children ? !val?.isPolAllowed : allChildrenNoAccess)
      )
    }
    return false
  }

  return (
    <SideNav
      isVisible={sideNavOpen}
      onRequestClose={() => {
        setIsSideNavOpen(!sideNavOpen)
      }}
      id="properties-side-nav"
      className={sideNavMinimized ? 'sideNavMinimizedClass' : undefined}
      canMinimize
      isMinimized={sideNavMinimized}
      onMinimizeChange={() => setSideNavMinimized(!sideNavMinimized)}
    >
      <SideNav.Header
        className={sideNavMinimized ? 'HeaderTitleMinimized' : 'HeaderTitle'}
      >
        <Link to="/" className="sideNavHeader">
          <img src={logo} alt="" className="logo" height={38} width={38} />
          <Heading size={4}>Skylight</Heading>
        </Link>
      </SideNav.Header>
      {
        <>
          <Divider />
          <SideNav.Navigation>
            {sideNavItems.map((val, outerKey: any) => {
              const navItem = (item: any, from: string) => {
                // Hide side nav item for POL users if they don't have access and also if the side nav item should be hidden if no required entitlement
                return checkIfPolAndNoAccess(item) ||
                  (item?.visibleForEntitledOnly && !item?.entitlements) ? (
                  <></>
                ) : item.isExternal ? (
                  <a
                    href={item?.path}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="C-SideNav__NavigationItem"
                    onClick={() => {
                      if (isMenuButton) {
                        setIsSideNavOpen(false)
                      }
                    }}
                  >
                    <span>{item?.label}</span>
                    <span className="hc-ml-min" aria-label="Opens in new tab">
                      <FontAwesomeIcon icon={faExternalLinkAlt as any} />
                    </span>
                  </a>
                ) : sideNavMinimized && from === 'parent-menu' ? (
                  <SideNav.NavigationItem>
                    {item?.label}
                    <EnterpriseIcon
                      icon={FolderIcon}
                      className="hc-mr-min"
                      style={{ marginLeft: -12 }}
                    />
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      style={{ marginTop: 2 }}
                    />
                  </SideNav.NavigationItem>
                ) : sideNavMinimized && from === 'parent-link' ? (
                  <SideNav.NavigationItem
                    to={item?.path}
                    selected={pathname === item?.path}
                    as={Link}
                  >
                    {item?.label}
                    {item.icon}
                  </SideNav.NavigationItem>
                ) : (
                  <SideNav.NavigationItem
                    className={
                      from === 'parent-link'
                        ? 'sideNavItemLink'
                        : from === 'parent-menu'
                          ? 'sideNavItemParent'
                          : undefined
                    }
                    selected={pathname === item?.path}
                    as={from === 'parent-menu' ? undefined : Link}
                    to={from === 'parent-menu' ? undefined : item?.path}
                    onClick={() => {
                      if (isMenuButton) {
                        setIsSideNavOpen(false)
                      }
                      trackClick(`Side nav: ${item?.path}`)
                    }}
                  >
                    {from === 'parent-menu' ? (
                      <>
                        <div className="button-text-vertical">
                          <span className={'bold-uppercase'}>
                            {item?.label}
                          </span>
                          <span> {item?.subLabel}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        {item?.label}
                        <br />
                        {item?.subLabel}
                      </>
                    )}
                  </SideNav.NavigationItem>
                )
              }
              return (
                <React.Fragment key={outerKey}>
                  {checkIfPolAndNoAccess(val) ? null : val.children ? (
                    <>
                      <SideNav.NavigationGroup
                        startExpanded={(val as any)?.startExpanded}
                      >
                        {navItem(val, 'parent-menu')}
                        <SideNav.NavigationGroupContent>
                          {val.children.map((subItem: any, key: any) => {
                            return (
                              <React.Fragment key={key}>
                                {navItem(subItem, 'children')}
                              </React.Fragment>
                            )
                          })}
                        </SideNav.NavigationGroupContent>
                      </SideNav.NavigationGroup>
                    </>
                  ) : (
                    navItem(val, 'parent-link')
                  )}
                  <Divider />
                </React.Fragment>
              )
            })}
          </SideNav.Navigation>
          <Divider />
        </>
      }
    </SideNav>
  )
}
