import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const ZolPublishedBoms = () => {
  return (
    <MicroFrontEnd
      name="ZolPublishedBoms"
      src={apiConfig.microFrontEnds.ZolPublishedBoms}
    />
  )
}

export default ZolPublishedBoms
