import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const MilestoneManagement = () => {
  return (
    <MicroFrontEnd
      name="MilestoneManagement"
      src={apiConfig.microFrontEnds.MilestoneManagement}
    />
  )
}

export default MilestoneManagement
