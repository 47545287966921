import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { mfeLabels } from '../utility/labels'
import { mfeNavUrls } from '../utility/url'

export const ProgramCreate = () => {
  const navigate = useNavigate()

  return (
    <MicroFrontEnd
      mode={MicroFrontEnd.Mode.Create}
      name="ProgramDetails"
      props={{
        onSuccess: (projectGroup: any) =>
          navigate(`/programs/${projectGroup.project_group_id}`),
        mfeLabels,
        mfeNavUrls,
      }}
      src={apiConfig.microFrontEnds.ProgramDetails}
    />
  )
}

export default ProgramCreate
