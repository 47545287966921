import * as React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { useParams } from 'react-router-dom'

export const DirectReport = () => {
  const { lanId } = useParams<any>()
  return (
    <div>
      <MicroFrontEnd
        name={'DirectReport'}
        src={apiConfig.microFrontEnds.DirectReportMfeUrl}
        props={{
          lanId: lanId,
        }}
      />
    </div>
  )
}
export default DirectReport
