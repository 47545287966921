import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const UnspscLookupTool = () => {
  return (
    <MicroFrontEnd
      name="UnspscLookupTool"
      src={apiConfig.microFrontEnds.UnspscLookupTool}
    />
  )
}
export default UnspscLookupTool
