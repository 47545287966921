import * as React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { useParams, matchPath, useLocation } from 'react-router-dom'
import { mfeNavUrls } from '../utility/url'
import {
  CHANGE_COMM_REPORT,
  CHANGE_GROUP_LIST,
  CHANGE_LOG_COMMUNICATION,
  CREATE_CHANGES,
} from '../utility/constants'

export const ProgramProjectChanges = () => {
  const { id } = useParams<any>()
  const location = useLocation()
  const isProgramChangeListPath = matchPath(
    `${CHANGE_GROUP_LIST}:id`,
    location.pathname,
  )
  const isProgramChangeGroupCreatePath = matchPath(
    CREATE_CHANGES,
    location.pathname,
  )
  const isProjectChangeLogPath =
    matchPath(CHANGE_LOG_COMMUNICATION, location.pathname) ||
    matchPath(`${CHANGE_LOG_COMMUNICATION}/:id`, location.pathname)

  const isIssuedChangesReportPath = matchPath(
    CHANGE_COMM_REPORT,
    location.pathname,
  )

  return (
    <MicroFrontEnd
      name="ProgramProjectChanges"
      src={apiConfig.microFrontEnds.ProgramProjectChanges}
      props={{
        mfeNavUrls,
        changeLogGroupId: isProgramChangeListPath ? id : undefined,
        isProgramChangeGroupCreate: isProgramChangeGroupCreatePath
          ? true
          : false,
        isProjectChangeLog: isProjectChangeLogPath ? true : false,
        changeLogId: isProjectChangeLogPath && id ? id : undefined,
        isIssuedChangesReport: isIssuedChangesReportPath ? true : false,
      }}
    />
  )
}

export default ProgramProjectChanges
