import { NonRetailItemV5, CatalogItem } from 'properties-ui-common'

export const constants = {
  unAuthorizedRoute: '/unauthorized',
  unAuthorized_text_1: 'Oops!',
  unAuthorized_text_2: 'You are not authorized to access this page',
  unAuthorized_button_1: 'Go home',
  unAuthorized_button_2: 'Go back',
}
// export const reportsNavData = [
//   {
//     href: 'https://target.domo.com/page/503343432',
//     name: 'Indirect Item OKR Metrics',
//   },
// ]

interface CatalogItemTaxonomy {
  l4_taxonomy: string
  unspsc_code: string
  csi_code: string
}

export interface WorksheetState {
  catalogItemDetails?: {
    aribaItemDetails?: CatalogItem
    nonRetailItemDetails?: NonRetailItemV5
    taxonomy?: CatalogItemTaxonomy
  }
  catalogItemMultiEdit?: { [key: string]: any }
  zolBom?: { change: boolean; userChange: boolean }
  zolBomPublished?: { active: boolean }
  catalogItemDetailsMultiEdit?: { [key: string]: any }
  hznSourcingGroupsMultiEdit?: { [key: string]: any }
  selectedItemsDownloadDocument?: { [key: string]: any }
  selectedItemsMultiEdit?: { [key: string]: any }
  redirectUrl?: string
  supplierCatalogItems?: { [key: string]: any }
}

export interface WorksheetAction {
  type: string
  // Please namespace your worksheet's actions
  state: WorksheetState
}

export const worksheetReducer = {
  reducer: (state: WorksheetState, action: WorksheetAction) => {
    const { state: actionState } = action
    switch (action.type) {
      // ZOL__ prefix is to namespace for Zoltar BOM MFEs
      case 'ZOL__BOM_CHANGE':
        return {
          ...state,
          zolBom: {
            change: actionState.zolBom?.change,
            userChange: actionState.zolBom?.userChange,
          },
        }
      // ZOL__ prefix is to namespace for Zoltar BOM MFEs
      case 'ZOL__PUBLISHED_BOM':
        return {
          ...state,
          zolBomPublished: {
            active: actionState.zolBomPublished?.active,
          },
        }
      // CID__ prefix is to namespace for Catalog Item Details worksheet
      case 'CID__NRI_FETCHED':
        return {
          ...state,
          catalogItemDetails: {
            ...state.catalogItemDetails,
            nonRetailItemDetails:
              actionState.catalogItemDetails?.nonRetailItemDetails,
          },
        }
      case 'CID__ARIBA_FETCHED':
        return {
          ...state,
          catalogItemDetails: {
            ...state.catalogItemDetails,
            aribaItemDetails: actionState.catalogItemDetails?.aribaItemDetails,
          },
        }
      case 'CID__TAXONOMY_FETCHED':
        return {
          ...state,
          catalogItemDetails: {
            ...state.catalogItemDetails,
            taxonomy: actionState.catalogItemDetails?.taxonomy,
          },
        }
      case 'CID__RESET':
        return {
          ...state,
          catalogItemDetails: {
            aribaItemDetails: {},
            nonRetailItemDetails: {},
            taxonomy: {},
          },
        }
      case 'CIME':
        return {
          ...state,
          catalogItemMultiEdit: {
            checkedItems: actionState.catalogItemMultiEdit?.checkedItems,
          },
        }
      case 'CIDME':
        return {
          ...state,
          catalogItemDetailsMultiEdit: {
            selected: actionState.catalogItemDetailsMultiEdit?.selected,
          },
        }
      case 'HZN__SOURCING_GROUPS':
        return {
          ...state,
          hznSourcingGroupsMultiEdit: {
            selectedSourcingGroups:
              actionState.hznSourcingGroupsMultiEdit?.selectedSourcingGroups,
          },
        }
      case 'INDIRECT_ITEMS':
        return {
          ...state,
          selectedItemsMultiEdit: {
            selectedItems: actionState.selectedItemsMultiEdit?.selectedItems,
          },
        }
      case 'SUPPLIER_CATALOGS':
        return {
          ...state,
          supplierCatalogItems: {
            selectedItems: actionState.supplierCatalogItems?.selectedItems,
          },
        }
      case 'DOWNLOAD_DOCUMENTS':
        return {
          ...state,
          selectedItemsDownloadDocument: {
            selectedDownloadItems:
              actionState.selectedItemsDownloadDocument?.selectedDownloadItems,
          },
        }
      case 'REDIRECT_URL':
        return {
          ...state,
          redirectUrl: actionState?.redirectUrl,
        }
      default:
        throw new Error()
    }
  },
}
