import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const CatalogUpdatesList = () => {
  return (
    <MicroFrontEnd
      name="CatalogUpdatesList"
      src={apiConfig.microFrontEnds.CatalogUpdatesList}
    />
  )
}

export default CatalogUpdatesList
