import * as React from 'react'
import {
  PageInfoProvider,
  EnterpriseProvider,
  WorksheetProvider,
  CustomContextProvider,
} from 'properties-ui-common'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { BrowserRouter } from 'react-router-dom'
import { AnalyticsProvider } from '@praxis/component-analytics'
import { AuthProvider } from '@praxis/component-auth'

import apiConfig from '../apiConfig'
import { Helmet } from 'react-helmet'
import { MainWrapper } from './core/MainWrapper'
import { worksheetReducer } from './core/MainWrapper/utilities/constants'
import { mfeNavUrls } from './MicroFrontEnds/utility/url'
import { mfeLabels } from './MicroFrontEnds/utility/labels'

const hostname = window && window.location && window.location.hostname

export const App = () => {
  // Check if the originating host contains partnersonline and take a different auth path
  if (hostname.includes('partnersonline')) {
    apiConfig.auth = {
      ...apiConfig.authPol,
    }
  }
  return (
    <>
      <Helmet>
        <title>{apiConfig.title}</title>
      </Helmet>
      <BrowserRouter>
        <AuthProvider {...apiConfig.auth}>
          <AnalyticsProvider
            eventManagerConfiguration={{
              appName: apiConfig.appName,
              apiKey: apiConfig.apiKey,
              url: apiConfig.analyticsUrl[
                hostname.includes('partnersonline') ? 'external' : 'internal'
              ],
              eventManagerKey: 'skylightAnalytics',
            }}
            trackLocations
          >
            <EnterpriseProvider env={apiConfig}>
              <PageInfoProvider mfeLabels={mfeLabels} mfeNavUrls={mfeNavUrls}>
                <WorksheetProvider {...worksheetReducer}>
                  <CustomContextProvider>
                    <ToastProvider location="top">
                      <MainWrapper />
                    </ToastProvider>
                  </CustomContextProvider>
                </WorksheetProvider>
              </PageInfoProvider>
            </EnterpriseProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  )
}
