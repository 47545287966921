import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const IndirectItemReports = () => {
  return (
    <MicroFrontEnd
      name="IndirectItemReports"
      src={apiConfig.microFrontEnds.IndirectItemReports}
    />
  )
}
export default IndirectItemReports
