import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const IndirectItemSearch = () => {
  return (
    <MicroFrontEnd
      name="IndirectItemSearch"
      src={apiConfig.microFrontEnds.IndirectItemSearch}
    />
  )
}
export default IndirectItemSearch
