import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const TeamAssignment = () => {
  return (
    <MicroFrontEnd
      name={'LocLevelTeamAssignments'}
      src={apiConfig.microFrontEnds.LocLevelTeamAssignments}
      props={{
        url: 'locLevelTeamAssignmentsUrl',
      }}
    />
  )
}

export default TeamAssignment
