import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { mfeLabels } from '../utility/labels'
import { mfeNavUrls } from '../utility/url'
import { PROGRAM_LIST_PATH } from '../utility/constants'

export const ProgramList = () => {
  const navigate = useNavigate()

  return (
    <MicroFrontEnd
      name="ProgramList"
      props={{
        onRowClick: (id: string) => navigate(`${PROGRAM_LIST_PATH}/${id}`),
        onCreateClick: () => navigate(`${PROGRAM_LIST_PATH}/create`),
        mfeLabels,
        mfeNavUrls,
      }}
      src={apiConfig.microFrontEnds.ProgramList}
    />
  )
}

export default ProgramList
