import * as React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { mfeLabels } from '../utility/labels'
import { mfeNavUrls } from '../utility/url'

export const ProjectDetails = () => {
  return (
    <MicroFrontEnd
      name="ProjectDetails"
      src={apiConfig.microFrontEnds.ProjectDetails}
      props={{ mfeNavUrls, mfeLabels }}
    />
  )
}

export default ProjectDetails
