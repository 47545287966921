import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { mfeNavUrls } from '../utility/url'
import { useParams } from 'react-router-dom'

export const PotentialImpact = () => {
  const { conceptId, programImpactId } = useParams<any>()
  return (
    <div>
      <MicroFrontEnd
        name={'PotentialImpact'}
        src={apiConfig.microFrontEnds.PotentialImpact}
        props={{
          mfeNavUrls,
          conceptId,
          programImpactId,
          potentialNewProgramDetailsPath: true,
        }}
      />
    </div>
  )
}
export default PotentialImpact
