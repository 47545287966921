import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { mfeNavUrls } from '../utility/url'
import {
  useParams,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  DESIGN_INITIATIVE_DASHBOARD,
  DESIGN_INITIATIVE_LIST,
  DESIGN_INITIATIVE_TERMINATE,
  SOURCE_TYPE_CONCEPT,
} from '../utility/constants'

export const DesignInitiatives = () => {
  const { id } = useParams<any>()
  const [redirect, setRedirect] = React.useState<string>()
  const location = useLocation()
  const navigate = useNavigate()
  if (redirect) {
    navigate(redirect, { replace: true })
  }

  const isDesignInitiativeTerminatePath = matchPath(
    `${DESIGN_INITIATIVE_TERMINATE}:id`,
    location.pathname,
  )

  const isDesignInitiativeListPath = matchPath(
    DESIGN_INITIATIVE_DASHBOARD,
    location.pathname,
  )

  return (
    <div>
      <MicroFrontEnd
        name={'DesignInitiatives'}
        src={apiConfig.microFrontEnds.DesignInitiatives}
        props={{
          mfeNavUrls,
          conceptId: id,
          onBack: () => setRedirect('/concept-dashboard'),
          header: isDesignInitiativeListPath
            ? `${DESIGN_INITIATIVE_LIST}`
            : 'Design Terminate',
          isDesignInitiativeTerminate: isDesignInitiativeTerminatePath,
          isDesignInitiativeList: isDesignInitiativeListPath,
          url: 'conceptRequestUrl',
          sourceType: SOURCE_TYPE_CONCEPT,
        }}
      />
    </div>
  )
}

export default DesignInitiatives
