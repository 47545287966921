import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const IndirectItemsMultiEdit = () => {
  return (
    <MicroFrontEnd
      name="IndirectItemsMultiEdit"
      src={apiConfig.microFrontEnds.IndirectItemMultiEdit}
    />
  )
}

export default IndirectItemsMultiEdit
