import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { matchPath, useLocation } from 'react-router-dom'
import { SOLUTION_REQUEST_DASHBOARD } from '../utility/constants'

export const SolutionRequests = () => {
  const location = useLocation()

  const isRequestListPath = matchPath(
    SOLUTION_REQUEST_DASHBOARD,
    location.pathname,
  )

  return (
    <div>
      <MicroFrontEnd
        name={'SolutionRequests'}
        src={apiConfig.microFrontEnds.SolutionRequests}
        props={{
          isRequestList: isRequestListPath,
          url: 'solutionRequestUrl',
        }}
      />
    </div>
  )
}

export default SolutionRequests
