import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const ItemAttrCompletionReports = () => {
  return (
    <MicroFrontEnd
      name="ItemAttrCompletionReports"
      src={apiConfig.microFrontEnds.ItemAttrCompletionReports}
    />
  )
}

export default ItemAttrCompletionReports
