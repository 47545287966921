import * as React from 'react'
import classnames from 'classnames'
import { Button, Grid, Anchor } from '@enterprise-ui/canvas-ui-react'
import { useNavigate, useLocation } from 'react-router-dom'
import { constants } from './constants'
import './styles.css'
interface UnauthorizedProps {}

export const Unauthorized: React.FC<UnauthorizedProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { unauthorizedRoute = '' } = location?.state ?? {}

  return (
    <Grid.Container justify="center" className="container">
      <Grid.Item>
        <div className="hc-pa-normal">
          <div>
            <div className="hc-fs-xl">
              {constants.unAuthorized_text_1} {constants.unAuthorized_text_2}
            </div>
            <div className={classnames('hc-fs-md', 'hc-ta-center')}>
              <Anchor className="C-Anchor" href={unauthorizedRoute}>
                {unauthorizedRoute}
              </Anchor>
            </div>
          </div>
          <div className="buttonDiv">
            <Button
              type="primary"
              size="expanded"
              title={constants.unAuthorized_button_1}
              onClick={() => navigate('/')}
            >
              {constants.unAuthorized_button_1}
            </Button>
            <Button
              className="hc-ml-lg"
              size="expanded"
              type="primary"
              title={constants.unAuthorized_button_2}
              onClick={() => navigate(-1)}
            >
              {constants.unAuthorized_button_2}
            </Button>
          </div>
        </div>
      </Grid.Item>
    </Grid.Container>
  )
}

export default Unauthorized
