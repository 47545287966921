import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const BimUserManagement = () => {
  return (
    <MicroFrontEnd
      name={'BimUserManagement'}
      src={apiConfig.microFrontEnds.BimUserManagement}
      props={
        {
          // id: id,
        }
      }
    />
  )
}

export default BimUserManagement
