import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const CatalogDetails = () => {
  return (
    <MicroFrontEnd
      name="CatalogDetails"
      src={apiConfig.microFrontEnds.CatalogDetails}
    />
  )
}

export default CatalogDetails
