import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const ResourcesNeeded = () => {
  return (
    <MicroFrontEnd
      name={'ResourcesNeeded'}
      src={apiConfig.microFrontEnds.ResourceNeeded}
      props={{
        url: 'ResourceNeededUrl',
      }}
    />
  )
}

export default ResourcesNeeded
