import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const LocationsSearch = () => {
  return (
    <MicroFrontEnd
      name="LocationsSearch"
      src={apiConfig.microFrontEnds.LocationsSearch}
    />
  )
}

export default LocationsSearch
