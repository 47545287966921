import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import {
  SPATIO_CREATE_PROJECT_LABEL,
  SPATIO_CREATE_PROJECT_PATH,
} from '../utility/constants'

export const SpatioListProjects = () => {
  return (
    <MicroFrontEnd
      name="SpatioListProjects"
      src={apiConfig.microFrontEnds.SpatioListProjects}
      props={{
        buttonText: SPATIO_CREATE_PROJECT_LABEL,
        buttonClickPath: SPATIO_CREATE_PROJECT_PATH,
      }}
    />
  )
}

export default SpatioListProjects
