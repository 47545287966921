import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const ZolPlannedBom = () => {
  return (
    <MicroFrontEnd
      name="ZolPlannedBom"
      src={apiConfig.microFrontEnds.ZolPlannedBom}
    />
  )
}

export default ZolPlannedBom
