import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const TeamsGroupManagement = () => {
  return (
    <MicroFrontEnd
      name={'TeamsGroupManagement'}
      src={apiConfig.microFrontEnds.TeamsGroupManagement}
      props={{
        url: '',
      }}
    />
  )
}

export default TeamsGroupManagement
