import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const ContractExpiration = () => {
  return (
    <MicroFrontEnd
      name="ExpiringContracts"
      props={{
        homePage: false,
      }}
      src={apiConfig.microFrontEnds.ExpiringContractsMfe}
    />
  )
}

export default ContractExpiration
