import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { SPATIO_LIST_PROJECTS_PATH } from '../utility/constants'

export const SpatioCreateProject = () => {
  return (
    <MicroFrontEnd
      name="SpatioCreateProject"
      src={apiConfig.microFrontEnds.SpatioCreateProject}
      props={{
        listProjectPath: SPATIO_LIST_PROJECTS_PATH,
      }}
    />
  )
}

export default SpatioCreateProject
