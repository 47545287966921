import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const IndirectItemRequests = () => {
  return (
    <MicroFrontEnd
      name="IndirectItemRequests"
      src={apiConfig.microFrontEnds.IndirectItemRequests}
    />
  )
}

export default IndirectItemRequests
