import * as React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { mfeLabels } from '../utility/labels'
import { mfeNavUrls } from '../utility/url'

export const ProgramDetails = () => {
  return (
    <MicroFrontEnd
      name="ProgramDetails"
      src={apiConfig.microFrontEnds.ProgramDetails}
      props={{ mfeNavUrls, mfeLabels }}
    />
  )
}

export default ProgramDetails
