import React from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  matchPath,
} from 'react-router-dom'
import { ProtectedElement } from '@praxis/component-auth'
import { useEntitlements } from 'properties-ui-common'
import { appRoutesArray } from './AppRouteItems'
import { constants } from '../utilities/constants'

interface AppRoutesProps {}

export const AppRoutes: React.FC<AppRoutesProps> = () => {
  const { entitlements } = useEntitlements()
  const isPolUser = entitlements?.pol?.read
  const location = useLocation()
  const navigate = useNavigate()

  // Redirect unauthorized external user
  React.useEffect(() => {
    if (isPolUser && location.pathname !== constants.unAuthorizedRoute) {
      const polRoute = appRoutesArray().find((val) =>
        matchPath(val.path, location.pathname),
      )
      if (!polRoute?.isPolAllowed) {
        navigate(constants.unAuthorizedRoute, {
          replace: true,
          state: { unauthorizedRoute: location.pathname },
        })
      }
    }
  }, [location, isPolUser, navigate])

  return (
    <Routes>
      {appRoutesArray().map((val: any) => {
        const Element = val?.component
        return !val?.protected ? (
          <Route
            element={<Element />}
            path={val?.isExact ? `${val?.path}/*` : val?.path}
            key={val?.path}
          />
        ) : (
          <Route
            path={val?.isExact ? `${val?.path}/*` : val?.path}
            key={val?.path}
            element={
              <ProtectedElement
                allowed={val?.allowed}
                unauthorizedRoute={constants.unAuthorizedRoute}
              >
                <Element />
              </ProtectedElement>
            }
          />
        )
      })}
    </Routes>
  )
}
