import { MicroFrontEnd } from 'properties-ui-common'
import { useParams } from 'react-router-dom'
import apiConfig from '../../../apiConfig'

export const IndirectItemVariations = () => {
  const { id } = useParams<any>()
  return (
    <MicroFrontEnd
      name="IndirectItemVariations"
      src={apiConfig.microFrontEnds.IndirectItemVariations}
      props={{
        tcin: id,
      }}
    />
  )
}

export default IndirectItemVariations
