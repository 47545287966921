import React from 'react'
import {
  Button,
  Grid,
  Layout,
  Avatar,
  Dropdown,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignOutAlt,
  faCommentMedical,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons'
import { useAuth } from 'properties-ui-common'
import apiConfig from '../../../../apiConfig'
import { UserInfo } from '../utilities/UserInfo'
import { FeedbackWidget } from './FeedbackWidget'
import '../MainWrapper.css'

interface MenuBarProps {
  railOpen: boolean
  setRailOpen: Function
  darkMode: boolean
  setDarkMode: Function
}

export const MenuBar: React.FC<MenuBarProps> = ({
  railOpen,
  setRailOpen,
  darkMode,
  setDarkMode,
}) => {
  const auth = useAuth()

  return (
    <>
      <Layout.GlobalActions>
        <Grid.Container align="center" justify="flex-end">
          <Grid.Item>
            <Input.Toggle
              id="dark_mode_switch"
              label="Dark Mode"
              value={darkMode}
              onChange={(event: any) => {
                setDarkMode(event.target.checked)
              }}
              className="darkModeToggleLabel"
            />
          </Grid.Item>
          {apiConfig.bannerEnvDisplay.length > 0 && (
            <Grid.Item>
              <span className="hc-bg-error hc-clr-contrast-weak hc-pa-dense">
                {apiConfig.bannerEnvDisplay}
              </span>
            </Grid.Item>
          )}
          <Button
            type="destructive"
            onClick={() => (railOpen ? setRailOpen(false) : setRailOpen(true))}
          >
            <FontAwesomeIcon icon={faCommentMedical as any} />
          </Button>{' '}
          <Grid.Item>
            <Dropdown location="bottom-right">
              <Grid.Container
                spacing="none"
                align="center"
                className="avatarContainer"
              >
                <Grid.Item>
                  <Avatar
                    aria-label="User initials"
                    className="hc-mr-none hc-pr-none"
                    color="teal"
                  >
                    <UserInfo nameType="initials" />
                  </Avatar>
                </Grid.Item>
                <Grid.Item>
                  <FontAwesomeIcon
                    className="hc-ml-dense"
                    color="lightGrey"
                    icon={faAngleDown as any}
                    size="lg"
                  />
                </Grid.Item>
              </Grid.Container>
              <Dropdown.Menu>
                <Dropdown.MenuItem className="hc-clr-black loggedInAs">
                  <span className="hc-mr-dense">
                    Logged in as <UserInfo nameType="full" />
                  </span>
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onClick={() => {
                    auth.logout()
                    sessionStorage.clear()
                  }}
                >
                  <span>Logout</span>
                  <span className="hc-pl-dense">
                    <FontAwesomeIcon
                      icon={faSignOutAlt as any}
                    ></FontAwesomeIcon>
                  </span>
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
      {railOpen && <FeedbackWidget onFeedback={() => setRailOpen(false)} />}
    </>
  )
}
