import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const ProjectTeamUpdate = () => {
  return (
    <MicroFrontEnd
      name={'ProjectTeamUpdate'}
      src={apiConfig.microFrontEnds.ProjectTeamUpdate}
    />
  )
}

export default ProjectTeamUpdate
