import React from 'react'
import { createRoot } from 'react-dom/client'
import { onError, reportWebVitals } from '@praxis/component-logging'

import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'

import * as PraxisAuth from '@praxis/component-auth'
import * as CanvasUI from '@enterprise-ui/canvas-ui-react'
import * as StyledComponents from 'styled-components'
import * as PropertiesUiCommon from 'properties-ui-common'
import * as ReactRouterDom from 'react-router-dom'
import * as Moment from 'moment'
import { App } from './components/App'

// Standard errors will be automatically logged to STDOUT when thrown.
// The logging sidecar in TAP will process and send logs to the appropriate environment in Kibana.
// Learn more about TAP sidecars: https://tapdocs.prod.target.com/runtime/sidecars/#_platform_sidecars
window.onerror = onError

declare global {
  interface Window {
    microFrontEnds: {
      [key: string]: React.FC<any>
    }
    sharedLibraries: {
      [key: string]: unknown
    }
  }
}

window.onerror = onError
window.microFrontEnds = {}
window.sharedLibraries = {
  CanvasUI,
  React,
  StyledComponents,
  PraxisAuth,
  PropertiesUiCommon,
  ReactRouterDom,
  Moment,
}

// If you want to start measuring performance in your app, enable the reportWebVitals()
// function below. Learn more: https://praxis.prod.target.com/components/logging#quick-start-metrics
reportWebVitals()

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
