import EnterpriseIcon, {
  BackroomLocationManagementIcon,
  HouseIcon,
} from '@enterprise-ui/icons'
import apiConfig from '../../../../apiConfig'
// import { deriveBasePath } from '../../../MicroFrontEnds/utility/url'
import {
  BIM_USER_MANAGEMENT_LABEL,
  BIM_USER_MANAGEMENT_PATH,
  CATALOG_ITEM_SEARCH_LABEL,
  CATALOG_ITEM_SEARCH_PATH,
  CATALOG_PARENT_LABEL,
  CATALOG_PARENT_SUBLABEL,
  CATALOG_UPDATES_LABEL,
  CATALOG_UPDATES_PATH,
  // DEMAND_PLANNING_PARENT_LABEL,
  // DEMAND_PLANNING_PARENT_SUBLABEL,
  CHANGE_COMM_REPORT_LABEL,
  CHANGE_COMM_REPORT,
  CONCEPT_HDR_LABEL,
  DESIGN_INITIATIVE_DASHBOARD,
  CONCEPT_SUB_LABEL,
  CONTRACT_EXPIRATION_PATH,
  CONTRACT_EXPIRATION_LABEL,
  HOME_LABEL,
  HOME_PATH,
  HZN_SOURCING_GROUPS_LABEL,
  HZN_SOURCING_GROUPS_PATH,
  INDIRECT_ITEM_SEARCH_LABEL,
  UNSPSC_LOOKUP_TOOL_LABEL,
  UNSPSC_LOOKUP_TOOL_PATH,
  INDIRECT_ITEM_SEARCH,
  INTAKE_HDR_LABEL,
  INTAKE_SUB_LABEL,
  ITEM_ATTR_REPORT_LABEL,
  ITEM_ATTR_REPORT,
  LOCATIONS_SEARCH_LABEL,
  LOCATIONS_SEARCH_PATH,
  PROGRAM_LABEL,
  PROGRAM_LIST_LABEL,
  PROGRAM_LIST_PATH,
  PROGRAM_SUB_LABEL,
  PROJECT_TEAM_UPDATE_LABEL,
  PROJECT_TEAM_UPDATE_PATH,
  PURCHASE_LABEL,
  PURCHASE_PATH,
  REQUEST_CREATE_LABEL,
  REQUEST_LIST_LABEL,
  RES_NEEDED_HDR_LABEL,
  RESOURCES_NEEDED_PATH,
  SOLUTION_REQUEST_CREATE,
  SOLUTION_REQUEST_DASHBOARD,
  SPATIO_CREATE_PROJECT_LABEL,
  SPATIO_CREATE_PROJECT_PATH,
  SPATIO_HDR_LABEL,
  SPATIO_LIST_PROJECTS_LABEL,
  SPATIO_LIST_PROJECTS_PATH,
  SPATIO_SUB_LABEL,
  TEAM_ASSIGNMENT_PATH,
  TEAM_ASSIGNMENT,
  TEAM_MANAGEMENT_LABEL,
  TEAM_MANAGEMENT_PATH,
  TEAMS_LABEL,
  TEAMS_SUB_LABEL,
  MILESTONE_MANAGEMENT_LABEL,
  MILESTONE_MANAGEMENT_PATH,
  VA_GRID_LIST_PATH,
  VA_GRID_LIST_LABEL,
  DESIGN_INITIATIVE_LIST,
  ZOL_PUBLISHED_BOMS_PATH,
  ZOL_PUBLISHED_BOMS_LABEL,
  INDIRECT_ITEM_REPORTS,
  INDIRECT_ITEM_REPORTS_LABEL,
  CATALOG_IMAGE_BULK_UPLOAD_PATH,
  CATALOG_IMAGE_BULK_UPLOAD_LABEL,
  NRI_REQUESTS_PATH,
  NRI_REQUESTS_LABEL,
} from '../../../MicroFrontEnds/utility/constants'
// import { reportsNavData } from '../utilities/constants'

export const sideNavigationArray = (entitlements) => {
  const isCatalogReadEntitled =
    entitlements?.ttsAdmin?.create || entitlements?.catalog?.read
  const isCatalogUpdateEntitled =
    entitlements?.ttsAdmin?.create || entitlements?.catalog?.update
  const isFileUploadEntitled =
    entitlements?.ttsAdmin?.create || entitlements?.purchase?.create

  const vendorAssignmentAllowed =
    entitlements?.vendorAssignment?.read || entitlements?.ttsAdmin?.create
  const isDemandEntitled =
    entitlements?.ttsAdmin?.create || entitlements?.demand?.read
  const isSourcingEntitled =
    entitlements?.ttsAdmin?.create ||
    (entitlements?.sourcing?.read && !entitlements?.procurement?.create)

  const isIpUser = entitlements?.catItem?.read || entitlements?.ttsAdmin?.create

  const isItemUpdateEntitled =
    entitlements?.item?.update || entitlements?.ttsAdmin?.create

  const itemRequestsEntitled =
    entitlements?.item?.create || entitlements?.item?.request

  /* The access for External users is controlled using the "isPolAllowed" flag being set to true or false. It will default to no access if the flag is not set to true  */
  return [
    {
      path: HOME_PATH,
      label: HOME_LABEL,
      entitlements: null,
      external: false,
      isPolAllowed: true,
      icon: <EnterpriseIcon icon={HouseIcon} />,
    },
    {
      label: INTAKE_HDR_LABEL,
      subLabel: INTAKE_SUB_LABEL,
      entitlements: null,
      external: false,
      children: [
        {
          path: SOLUTION_REQUEST_CREATE,
          label: REQUEST_CREATE_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: true,
        },
        {
          path: SOLUTION_REQUEST_DASHBOARD,
          label: REQUEST_LIST_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: false,
        },
      ],
    },
    {
      label: CONCEPT_HDR_LABEL,
      subLabel: CONCEPT_SUB_LABEL,
      entitlements: null,
      external: false,
      children: [
        {
          path: DESIGN_INITIATIVE_DASHBOARD,
          label: DESIGN_INITIATIVE_LIST,
          entitlements: null,
          external: false,
          isPolAllowed: false,
        },
      ],
    },
    {
      label: PROGRAM_LABEL,
      subLabel: PROGRAM_SUB_LABEL,
      entitlements: null,
      external: false,
      children: [
        {
          path: PROGRAM_LIST_PATH,
          label: PROGRAM_LIST_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: true,
        },
        {
          label: MILESTONE_MANAGEMENT_LABEL,
          path: MILESTONE_MANAGEMENT_PATH,
          entitlements: null,
          isPolAllowed: false,
          external: false,
        },
        {
          path: apiConfig.externalLinks.procore,
          label: 'Procore',
          isExternal: true,
          entitlements: null,
          external: false,
          isPolAllowed: true,
        },
      ],
    },
    {
      label: TEAMS_LABEL,
      subLabel: TEAMS_SUB_LABEL,
      entitlements: null,
      external: false,
      children: [
        {
          path: TEAM_ASSIGNMENT_PATH,
          label: TEAM_ASSIGNMENT,
          entitlements: null,
          external: false,
          isPolAllowed: true,
        },
        {
          path: TEAM_MANAGEMENT_PATH,
          label: TEAM_MANAGEMENT_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: true,
        },
        {
          path: RESOURCES_NEEDED_PATH,
          label: RES_NEEDED_HDR_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: false,
        },
        {
          path: PROJECT_TEAM_UPDATE_PATH,
          label: PROJECT_TEAM_UPDATE_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: true,
        },
        {
          path: BIM_USER_MANAGEMENT_PATH,
          label: BIM_USER_MANAGEMENT_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: false,
        },
      ],
    },
    {
      label: LOCATIONS_SEARCH_LABEL,
      path: LOCATIONS_SEARCH_PATH,
      entitlements: null,
      isPolAllowed: true,
      external: true,
      icon: <EnterpriseIcon icon={BackroomLocationManagementIcon} />,
    },
    {
      label: CATALOG_PARENT_LABEL,
      subLabel: CATALOG_PARENT_SUBLABEL,
      entitlements: null,
      external: false,
      children: [
        {
          path: HZN_SOURCING_GROUPS_PATH,
          label: HZN_SOURCING_GROUPS_LABEL,
          entitlements: isSourcingEntitled,
          visibleForEntitledOnly: true,
          external: false,
        },
        {
          path: VA_GRID_LIST_PATH,
          label: VA_GRID_LIST_LABEL,
          entitlements: vendorAssignmentAllowed,
          external: false,
          visibleForEntitledOnly: true,
        },
        {
          path: ZOL_PUBLISHED_BOMS_PATH,
          label: ZOL_PUBLISHED_BOMS_LABEL,
          entitlements: isDemandEntitled,
          external: false,
          visibleForEntitledOnly: true,
        },
        {
          path: UNSPSC_LOOKUP_TOOL_PATH,
          label: UNSPSC_LOOKUP_TOOL_LABEL,
          entitlements: null,
          external: false,
        },
        {
          path: PURCHASE_PATH,
          label: PURCHASE_LABEL,
          entitlements: isFileUploadEntitled,
          external: false,
        },
        {
          path: CONTRACT_EXPIRATION_PATH,
          label: CONTRACT_EXPIRATION_LABEL,
          entitlements: isSourcingEntitled,
          external: false,
          visibleForEntitledOnly: true,
        },
      ],
    },

    // {
    //   label: DEMAND_PLANNING_PARENT_LABEL,
    //   subLabel: DEMAND_PLANNING_PARENT_SUBLABEL,
    //   entitlements: null,
    //   external: false,
    //   children: [
    //     {
    //       path: ZOL_PLANNED_BOM_PATH,
    //       label: ZOL_PLANNED_BOM_LABEL,
    //       entitlements: vendorAssignmentAllowed,
    //       external: false,
    //       visibleForEntitledOnly: true,
    //     },
    //   ],
    // },
    {
      label: 'Item & Catalog Operations',
      subLabel: 'Manage TCINs & Catalog Items',
      entitlements: null,
      external: false,
      children: [
        {
          path: INDIRECT_ITEM_SEARCH,
          label: INDIRECT_ITEM_SEARCH_LABEL,
          entitlements: null,
          external: false,
        },
        {
          path: CATALOG_ITEM_SEARCH_PATH,
          label: CATALOG_ITEM_SEARCH_LABEL,
          entitlements: isIpUser,
          visibleForEntitledOnly: true,
          external: false,
        },
        {
          path: CATALOG_UPDATES_PATH,
          label: CATALOG_UPDATES_LABEL,
          entitlements: isCatalogReadEntitled,
          visibleForEntitledOnly: true,
          external: false,
        },
        {
          path: CATALOG_IMAGE_BULK_UPLOAD_PATH,
          label: CATALOG_IMAGE_BULK_UPLOAD_LABEL,
          entitlements: isCatalogUpdateEntitled,
          visibleForEntitledOnly: true,
          external: false,
        },
        {
          path: ITEM_ATTR_REPORT,
          label: ITEM_ATTR_REPORT_LABEL,
          entitlements: isItemUpdateEntitled,
          visibleForEntitledOnly: true,
          external: false,
        },
        {
          path: INDIRECT_ITEM_REPORTS,
          label: INDIRECT_ITEM_REPORTS_LABEL,
          entitlements: isItemUpdateEntitled,
          visibleForEntitledOnly: true,
          external: false,
        },
        {
          path: NRI_REQUESTS_PATH,
          label: NRI_REQUESTS_LABEL,
          entitlements: itemRequestsEntitled,
          visibleForEntitledOnly: true,
          external: false,
        },
        // ...reportsNavData.map((navItem) => {
        //   const { href, name } = navItem
        //   return {
        //     path: href,
        //     label: name,
        //     isExternal: true,
        //     entitlements: null,
        //     external: false,
        //   }
        // }),
      ],
    },
    {
      label: 'Reports',
      entitlements: null,
      external: false,
      children: [
        {
          path: CHANGE_COMM_REPORT,
          label: CHANGE_COMM_REPORT_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: true,
        },
      ],
    },
    {
      label: SPATIO_HDR_LABEL,
      subLabel: SPATIO_SUB_LABEL,
      entitlements: null,
      external: false,
      children: [
        {
          path: SPATIO_CREATE_PROJECT_PATH,
          label: SPATIO_CREATE_PROJECT_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: false,
        },
        {
          path: SPATIO_LIST_PROJECTS_PATH,
          label: SPATIO_LIST_PROJECTS_LABEL,
          entitlements: null,
          external: false,
          isPolAllowed: false,
        },
        {
          path: apiConfig.externalLinks.spatio_reports,
          label: 'Reports',
          isExternal: true,
          entitlements: null,
          external: false,
          isPolAllowed: false,
        },
      ],
    },
  ]
}
