import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
import { mfeNavUrls } from '../utility/url'

export const IndirectItemMultiDocDownload = () => {
  return (
    <MicroFrontEnd
      name="IndirectItemMultiDocDownload"
      src={apiConfig.microFrontEnds.IndirectItemMultiDocDownload}
      props={{
        mfeNavUrls,
      }}
    />
  )
}
export default IndirectItemMultiDocDownload
