import * as React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const ReassignWork = () => {
  return (
    <MicroFrontEnd
      name={'ReassignWork'}
      src={apiConfig.microFrontEnds.ReassignWorkMfeUrl}
    />
  )
}

export default ReassignWork
