import React from 'react'
import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'
export const Purchase = () => {
  return (
    <MicroFrontEnd name="Purchase" src={apiConfig.microFrontEnds.Purchase} />
  )
}

export default Purchase
